<route-meta>
	{
	"requiresAuth": true,
	"title": "Dashboard"
	}
</route-meta>

<template>
	<div class="">

		<latest-logs></latest-logs>

		<!--

		<chart class="position-relative"></chart>

		<div>
			<button class="btn btn-success mr-1">SUCCESS</button>
			<button class="btn btn-danger mr-1">DANGER</button>
			<button class="btn btn-warning mr-1">WARNING</button>
			<button class="btn btn-info mr-1">INFO</button>
			<button class="btn btn-primary mr-1">PRIMARY</button>
			<button class="btn btn-secondary mr-1">SECONDARY</button>
			<button class="btn btn-light mr-1">LIGHT</button>
			<button class="btn btn-dark mr-1">DARK</button>
		</div>

		<div class="mt-2">
			<button class="btn btn-outline-success mr-1">SUCCESS</button>
			<button class="btn btn-outline-danger mr-1">DANGER</button>
			<button class="btn btn-outline-warning mr-1">WARNING</button>
			<button class="btn btn-outline-info mr-1">INFO</button>
			<button class="btn btn-outline-primary mr-1">PRIMARY</button>
			<button class="btn btn-outline-secondary mr-1">SECONDARY</button>
			<button class="btn btn-outline-light mr-1">LIGHT</button>
			<button class="btn btn-outline-dark mr-1">DARK</button>
		</div>

		<div class="row mt-4">
			<div class="col" v-for="i in 4">
				<div class="card">
					<div class="card-body">
						BODY
					</div>
					<div class="card-footer">
						FOOTER
					</div>
				</div>
			</div>
		</div>

		-->

	</div>
</template>

<script>
	import Chart from '@/components/ui/chart'
	import LatestLogs from '@/components/dashboard/latest-logs'

	export default {
		components: {
			Chart,
			LatestLogs
		},
	}
</script>
