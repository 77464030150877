<template>
	<div id="app">
		<div id="chart-container">
			<fusioncharts
					:type="type"
					:width="width"
					:height="height"
					:data-format="dataFormat"
					:data-source="datasource"
			>
			</fusioncharts>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import VueFusionCharts from 'vue-fusioncharts';
	import FusionCharts from 'fusioncharts';
	//import Column2D from 'fusioncharts/fusioncharts.charts';
	import Line from 'fusioncharts/fusioncharts.charts'
	import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

	Vue.use(VueFusionCharts, FusionCharts, Line, FusionTheme);

	export default {
		name: 'chart',
		data() {
			return {
				"type": "line",
				"renderAt": "chart-container",
				"width": "550",
				"height": "50",
				"dataFormat": "json",
				"datasource": {
					"data": [{
						"label": "Mon",
						"value": "15123"
					}, {
						"label": "Tue",
						"value": "14233"
					}, {
						"label": "Wed",
						"value": "25507"
					}]
				}
			}
		}
	}
</script>
