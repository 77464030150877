<template>
	<div>
		<vue-good-table
				:columns="columns"
				:rows="latest">
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field == 'Tags'">
				  <span v-for="tag in props.row.Tags" v-text="tag" class="badge badge-primary"></span>
				</span>
				<span v-else>
				  {{props.formattedRow[props.column.field]}}
				</span>
			</template>
		</vue-good-table>
	</div>
</template>

<script>
	import api from '@/api'

	export default {
		data() {
			return {
				latest: [],
				columns: [
					{
						label: 'Date',
						field: 'CreatedAt'
					},
					{
						label: 'Ip Address',
						field: 'IpAddress',
					},
					{
						label: 'Action',
						field: 'Action',
					},
					{
						label: 'Tags',
						field: 'Tags',
					}
				]
			}
		},
		async created() {
			this.latest = await api.Me.lastLogins()
		}
	}
</script>
